import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import logo from '../dashboard/logo.png'
import './style.css'
import theme from '../theme';
import { grey } from '@mui/material/colors';
const LoadingPage = ({ loading }) => {
    // Lógica de animação pode ser mantida como está
    // ...
  
    return (
      <svg className="pl" viewBox="0 0 200 200" width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
            <stop offset="0%" stopColor="#DF1829" />
            <stop offset="100%" stopColor="#C4161C" />
          </linearGradient>
        </defs>
        <circle
          className="pl__ring"
          cx="100"
          cy="100"
          r="82"
          fill="none"
          stroke="url(#pl-grad1)"
          strokeWidth="36"
          strokeLinecap="round"
          transform="rotate(-90,100,100)"
        />
      </svg>
    );
  };
const LoadingComponent = ({ loading }) => {
    return (
        (
            <Container  disableGutters maxWidth={false}
                sx={{
                    position: 'absolute',
                    bgcolor:  theme.palette.mode == "dark" ? grey[900] : 'transparent',
                    height: '100vh',
                    padding: 0,
                    
                }}
            >
                <Box display={'flex'} flexDirection={"column"} justifyContent={"center"} alignContent={"center"} alignItems={'center'} height={'100vh'}>
                    <Box mb={3}>
                        <img src={logo} alt="Logo" style={{ width: '200px', height: 'auto' }} />
                    </Box>
                    <LoadingPage loading={loading} />
                </Box>
            </Container>
        )
    );
};

export default LoadingComponent;
